import React from "react"
import {graphql, Link} from "gatsby"
import {GatsbyImage} from "gatsby-plugin-image"

import Layout from "../layout"

import {GatsbySeo} from "gatsby-plugin-next-seo"
import HeaderH1 from "../headerH1";

const PrismicDOM = require("prismic-dom")

const BlogOverview = (data, {title, description, url}) => {
  const posts = [...data.featured.nodes, ...data.normal.nodes]

  return (
    <>
      <GatsbySeo
        title={title}
        description={description}
        openGraph={{
          url: url,
          title: title,
          description: description,
          site_name: "LinkFive - Successful Subscriptions",
        }}
      />
      <Layout fullWave="false" bottomWave="false" callToAction="true" utmSource="blog">

        <section className="bg-white py-6 mt-12 max-w-6xl mx-auto">
          <div className="container w-5/6 mx-auto md:px-2 px-0 pt-12 pb-12 text-gray-800 bg-white ">
            <HeaderH1
              headerText={title}
              widthClass="md:w-4/6"
            />

            <div className="flex justify-center mt-6">
              <Link to="/flutter-blog/" className="px-1">
                <button
                  className={(url.indexOf("flutter-blog") > 0 ? "action-button-small" : "secondary-action-button-small") + " focus:outline-none focus:shadow-outline"}>
                  Flutter
                </button>
              </Link>
              <Link to="/blog/product-updates/" className="px-1">
                <button
                  className={(url.indexOf("product-updates") > 0 ? "action-button-small" : "secondary-action-button-small") + " focus:outline-none focus:shadow-outline"}>
                  Product Updates
                </button>
              </Link>
            </div>

            <div className="flex flex-wrap -mx-4 mt-6 -mb-4">
              {posts.map(node => {
                const first_publication_date = PrismicDOM.Date(node.first_publication_date)

                const first_publication_date_formatted = new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                }).format(first_publication_date);

                const last_publication_date = PrismicDOM.Date(node.last_publication_date)

                const last_publication_date_formatted = new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                }).format(last_publication_date)

                const data = node.data;
                const {tag} = data;

                let tagName = "Flutter"
                if (tag) {
                  // capitalize
                  tagName = tag
                    .trim()
                    .replace("-", " ")
                    .toLowerCase()
                    .replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
                }

                let linkTo = `/flutter-blog/${node.uid}/`
                if(tag === "product-update"){
                  linkTo = `/blog/product-updates/${node.uid}/`
                }

                return (
                  <div className="p-4 md:w-1/2">
                    <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                      <Link to={linkTo}><GatsbyImage
                        className="lg:h-48 md:h-36 w-full object-cover object-center"
                        image={data.hero_image.localFile.childImageSharp.gatsbyImageData}
                        objectFit="cover"
                        alt="Hero Image"
                      /></Link>
                      <div className="p-6">
                        {/* CHIP */}
                        <div className="flex flex-wrap -ml-1">
                          <div
                            className="flex justify-center items-center m-1 font-medium py-2 px-3 bg-white rounded-full text-l5-secondary bg-l6-secondary">
                            <div
                              className="text-xs font-normal leading-none max-w-full flex-initial">{tagName}</div>
                          </div>
                        </div>
                        <p
                          className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1 mt-2">LinkFive</p>
                        <Link to={linkTo}><h2
                          className="title-font text-lg font-medium text-gray-900 mb-3">{data?.title?.text}</h2>
                        </Link>
                        <p className="leading-relaxed mb-3">{data.meta_description?.text &&
                        data.meta_description.text.length > 0 ? (
                          <p className="py-4">{data.meta_description.text}</p>
                        ) : (
                          <p className="py-4">
                            {data.rich_text.text.substring(0, 200)}...
                          </p>
                        )}</p>
                        <div className="flex items-center flex-wrap ">
                          <Link to={linkTo}
                                className="text-l6-secondary-variant inline-flex items-center md:mb-2 lg:mb-0">Read
                            <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"
                                 fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path d="M5 12h14"/>
                              <path d="M12 5l7 7-7 7"/>
                            </svg>
                          </Link>
                          <span
                            className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1">
                            {last_publication_date_formatted}
              </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default BlogOverview
